import React, { useEffect } from 'react'
import HeroSlider from '../../components/HeroSlider'
import Marquee from '../../components/Marquee'
import OurCompany from '../../components/OurCompany'
import ByCategory from '../../components/ByCategory'
import SellingProducts from '../../components/SellingProducts'
import Brands from '../../components/Brands'
import CustomUniforms from '../../components/CustomUniforms'
import Testimonial from '../../components/Testimonial'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <HeroSlider />
            <Marquee />
            <OurCompany />
            <ByCategory />
            <SellingProducts />
            <Brands />
            <CustomUniforms />
            <Testimonial />
        </>
    )
}

export default Home