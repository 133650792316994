import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeroImg from "../images/bannerimg.png"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroSlider = () => {

  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="hero-slider">
        <Slider {...settings}>
          <div className="hero-slider_img_bg" >
            <div className="hero_img">
              <img className="hero_img0" src={HeroImg} alt=""
              data-aos="zoom-in" data-aos-duration="2000"
              />
            </div>
          </div>
          <div className="hero-slider_img_bg" >
            <div className="hero_img">
              <img className="hero_img0" src={HeroImg} alt=""
              data-aos="zoom-in" data-aos-duration="2000"
              />
            </div>
          </div>

        </Slider>
      </div>

    </>
  );
}

export default HeroSlider