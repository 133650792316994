import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Logo from '../images/logo2.png'

import { FaYoutube, FaInstagramSquare, FaTwitter } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { AiFillFacebook } from "react-icons/ai";


const Footer = () => {


  return (
    <>
      <footer>
        <section className='footer_section2'>
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footer_logo_section">
                  <div className="footer_logo">
                    <Link to="/">
                      <img src={Logo} alt="" />
                    </Link>
                  </div>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <div className='footer_icon'>
                    <ul>
                      <li><Link target="_blank"><AiFillFacebook /></Link></li>
                      <li><Link target="_blank"><FaInstagramSquare /></Link></li>
                      <li><Link target="_blank"><FaTwitter /></Link></li>
                      <li><Link target="_blank"><FaYoutube /></Link></li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={2}>
                <div class="footer-links">
                  <h5>Quick Links</h5>
                  <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/">about us</Link></li>
                    <li><Link to="/">football helmets</Link></li>
                    <li><Link to="/">football helmets</Link></li>
                    <li><Link to="/">football helmet reconditioning</Link></li>
                    <li><Link to="/">football shoulder pads</Link></li>
                    <li><Link to="/">face mask</Link></li>
                    <li><Link to="/">Parts & accessories</Link></li>
                    <li><Link to="/">custom uniforms</Link></li>
                    <li><Link to="/">contact us</Link></li>
                  </ul>
                </div>
              </Col>
              <Col lg={2}>
                <div class="footer-links">
                  <h5>Outher Links</h5>
                  <ul>
                    <li><Link to="/">privacy policy</Link></li>
                    <li><Link to="/">TERMS & CONDITIONS</Link></li>
                  </ul>
                </div>
              </Col>
              <Col lg={4}>
                <div class="footer-links">
                  <h5>Newsletter</h5>
                  <div>
                    <form action="" className='footer-form-input'>
                      <input type="text" placeholder='Enter your email' />
                      <button>Send</button>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className='footer_section3'>
          <Container>
            <Row>
              <Col sm={12}>
                <p className='footer_section3_p'>
                Copyright © 2024 armorzone All Rights Reserved.
                </p>
              </Col >
            </Row >
          </Container >
        </section >
      </footer>

    </>

  )
}

export default Footer