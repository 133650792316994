import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import SectionName from './SectionName'
import Image from '../images/selling-img.png'
import Image2 from '../images/selling-img2.png'
import Image3 from '../images/selling-img3.png'
import Image4 from '../images/selling-img4.png'
import Image5 from '../images/shield-icon.png'
import { IoStarSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { RxArrowRight } from "react-icons/rx";




const SellingProducts = () => {
    return (
        <>
            <section>
                <Container>
                    <Row>
                        <Col lg="6">
                            <div className="selling-title">
                                <img src={Image5} alt="sheild icon" />
                                <SectionName title="Best" sub_title="Selling" sub_title2="Products" />
                            </div>
                        </Col>
                        <Col lg="6" className="selling-right"> 
                            <div className="selling-right-text">
                                <p>
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                                </p>
                                <button className='custom_btn_card'><Link to="#">View All</Link></button>
                            </div>
                        </Col>
                    </Row>
                    <div className="selling-cards" data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
                        <Card className='selling-card margin_top'>
                            <Link className='selling-card-img'>
                                <Card.Img variant="top" src={Image} />
                            </Link>
                            <Card.Body>
                                <div className="selling-rating">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <span>
                                        <IoStarSharp />
                                    </span>
                                </div>
                                <h5 className='selling-card-title'>Riddell SpeedFlex</h5>
                                <p className='selling-card-text'>
                                    Lorem Ipsum has been the industry's standard dummy text ever
                                </p>
                                <div className='selling-card-footer'>
                                    <button className='selling-card-arrow'><Link><RxArrowRight /></Link></button>
                                    <h2>$280.00</h2>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='selling-card margin_top1'>
                            <Link className='selling-card-img'>
                                <Card.Img variant="top" src={Image2} />
                            </Link>
                            <Card.Body>
                                <div className="selling-rating">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <span>
                                        <IoStarSharp />
                                    </span>
                                </div>
                                <h5 className='selling-card-title'>VICIS Zero 2</h5>
                                <p className='selling-card-text'>
                                    Lorem Ipsum has been the industry's standard dummy text ever
                                </p>
                                <div className='selling-card-footer'>
                                    <button className='selling-card-arrow'><Link><RxArrowRight /></Link></button>
                                    <h2>$280.00</h2>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='selling-card margin_top'>
                            <Link className='selling-card-img'>
                                <Card.Img variant="top" src={Image3} />
                            </Link>
                            <Card.Body>
                                <div className="selling-rating">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <span>
                                        <IoStarSharp />
                                    </span>
                                </div>
                                <h5 className='selling-card-title'>Riddell sPX Shoulder Pads</h5>
                                <p className='selling-card-text'>
                                    Lorem Ipsum has been the industry's standard dummy text ever
                                </p>
                                <div className='selling-card-footer'>
                                    <button className='selling-card-arrow'><Link><RxArrowRight /></Link></button>
                                    <h2>$280.00</h2>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='selling-card margin_top2'>
                            <Link className='selling-card-img'>
                                <Card.Img variant="top" src={Image4} />
                            </Link>
                            <Card.Body>
                                <div className="selling-rating">
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <IoStarSharp />
                                    <span>
                                        <IoStarSharp />
                                    </span>
                                </div>
                                <h5 className='selling-card-title'>Adult Padded Football</h5>
                                <p className='selling-card-text'>
                                    Lorem Ipsum has been the industry's standard dummy text ever
                                </p>
                                <div className='selling-card-footer'>
                                    <button className='selling-card-arrow'><Link><RxArrowRight /></Link></button>
                                    <h2>$280.00</h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default SellingProducts  