import './App.css';
import './default.css';
import './dashboard.css';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/home/Home';
import ScrollToTop from './components/ScrollToTop';
// import About from './pages/about/About';
// import Testimonials from './pages/testimonials/Testimonials';
// import Blogs from './pages/blogs/Blogs';
// import SingleProduct from './pages/SingleProduct/SingleProduct';
// import Faqs from './pages/help-&-faq/Faqs';
// import Contact from './pages/Contact/Contact';
// import PageNotFound from './pages/PageNotFound/PageNotFound';
// import Shop from './pages/shop/Shop';
// import Login from './pages/login/Login';
// import Register from './pages/register/Register';
// import Cart from './pages/cart/Cart';
// import CheckOut from './pages/checkout/CheckOut';
// import OrderTracking from './pages/orderTracking/OrderTracking';
// import OrderSearch from './pages/orderTracking/OrderSearch';

// ******** dashboard Links ********
// import SideBar from './admin/components/sidebar/SideBar';
// import Overview from './admin/pages/Overview';
// import Orders from './admin/pages/Orders';
// import Addresses from './admin/pages/Addresses';
// import Invoice from './admin/pages/invoice/Invoice';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route index element={<Home />} />
          {/* <Route path='/about' element={<About />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/blogs' element={<Blogs />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/single-product/:id' element={<SingleProduct />} />
          <Route path='/checkOut' element={<CheckOut />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='single-orders/:id' element={<OrderTracking />} />
          <Route path='order-search' element={<OrderSearch />} />
          <Route path="*" element={<PageNotFound />} /> */}

          {/********* dashboard Links *********/}
          {/* <Route path="dashboard" element={<WithSidebar />}>
            <Route path="overview" element={<Overview />} />
            <Route path="orders" element={<Orders />} />
            <Route path="addresses" element={<Addresses />} />
          </Route> */}
          {/* <Route path="invoice-show/:id" element={<Invoice />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

// const WithSidebar = () => {
//   return (
//     <SideBar>
//       <Outlet />
//     </SideBar>
//   );
// };

export default App;
