import React from 'react'
import Marquee from "react-fast-marquee";

const marquee = () => {
    return (
        <>
        <div className='asdasd'>
            <Marquee direction="left" className="marquee-white">
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
            </Marquee>
            <Marquee direction="right" className="marquee-black">
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                <div className='marquee-content'>
                    <p>*</p>
                    <p>ARMOUR <span>ZONE</span></p>
                </div>
                
            </Marquee>
        </div>
        </>
    )
}

export default marquee