import React from 'react'
import { Container, Row } from 'react-bootstrap'
import SectionName from './SectionName'
import Image from '../images/brandsLogo-1.png'
import Image2 from '../images/brandsLogo-2.png'
import Image3 from '../images/brandsLogo-3.png'
import Image4 from '../images/brandsLogo-4.png'
import Image5 from '../images/brandsLogo-5.png'

const Brands = () => {
    return (
        <>
            <section className='brands-bg'>
                <Container>
                    <Row>
                        <div className="category-title p-5">
                            <SectionName title="Armore Zone" sub_title="Brands" sub_title2="" />
                        </div>
                    </Row>
                    <div className="brands-logo" >
                        <span data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
                            <img src={Image} alt="" />
                        </span>
                        <span className="mt-4" data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
                            <img src={Image2} alt="" />
                        </span>
                        <span data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
                            <img src={Image3} alt="" />
                        </span>
                        <span className="mt-4" data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear"> 
                            <img src={Image4} alt="" />
                        </span>
                        <span data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
                            <img src={Image5} alt="" />
                        </span>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Brands