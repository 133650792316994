import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import SectionName from './SectionName'
import Image from '../images/category-1.png'
import Image2 from '../images/category-2.png'
import Image3 from '../images/category-3.png'
import { Link } from 'react-router-dom'
import { FaArrowRightLong } from "react-icons/fa6";


const ByCategory = () => {
    return ( 
        <>
            <section className='category-bg'>
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="category-title">
                                <SectionName title="About" sub_title="BY" sub_title2="CATEGORY" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="category-cards" data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
                            <Card className='category-card'>
                                <div className='category-card-img'>
                                    <Card.Img src={Image} />
                                </div>
                                <Card.Body className='category-card-body'>
                                    <Card.Title className='category-card-title'>Shoulder Pads</Card.Title>
                                    <p className='category-card-sub-title'>
                                        Lorem Ipsum has been the industry's standard dummy text ever
                                    </p>
                                    <button className='category-card-btn'><Link><FaArrowRightLong /></Link></button>
                                </Card.Body>
                            </Card>
                            <Card className='category-card'>
                                <div className='category-card-img'>
                                    <Card.Img src={Image2} />
                                </div>
                                <Card.Body className='category-card-body'>
                                    <Card.Title className='category-card-title'>Football Apparel & Accrssories</Card.Title>
                                    <p className='category-card-sub-title'>
                                        Lorem Ipsum has been the industry's standard dummy text ever
                                    </p>
                                    <button className='category-card-btn'><Link><FaArrowRightLong /></Link></button>
                                </Card.Body>
                            </Card>
                            <Card className='category-card'>
                                <div className='category-card-img'>
                                    <Card.Img src={Image3} />
                                </div>
                                <Card.Body className='category-card-body'>
                                    <Card.Title className='category-card-title'>Helmet Parts</Card.Title>
                                    <p className='category-card-sub-title'>
                                        Lorem Ipsum has been the industry's standard dummy text ever
                                    </p>
                                    <button className='category-card-btn'><Link><FaArrowRightLong /></Link></button>
                                </Card.Body>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ByCategory