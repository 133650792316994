import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionName from './SectionName'
import { Link } from 'react-router-dom'
import Image from '../images/custom-img.png'

const CustomUniforms = () => {
    return (
        <>
            <section className='CustomUniforms-bg'>
                <Container>
                    <Row className='CustomUniforms-main'>
                        <Col lg="5">
                            <div className="CustomUniforms-title">
                                <SectionName title="Armore Zone" sub_title="Custom Uniforms" sub_title2="" />
                                <div className="CustomUniforms-content" data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear">
                                    <p>
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                                        of type and scrambled it to make a type specimen book.
                                    </p>
                                </div>
                                <button className='custom_btn-white'><Link to="#">Customize Now</Link></button>
                            </div>
                        </Col>
                        <Col lg="7">
                            <img className='CustomUniforms-img' src={Image} alt="" data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CustomUniforms