import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { IoStarSharp } from 'react-icons/io5'
import Image from '../images/testimonial-1.png'
import Image2 from '../images/testimonial-2.png'
import Image3 from '../images/viewAll.png'
import SectionName from './SectionName'
import { Link } from 'react-router-dom'

const Testimonial = () => {
    return (
        <>
            <section className='testimonial-bg'>
                <Container>
                    <Row>
                        <Col lg="9">
                            <div className="testimonial-cards" data-aos="fade-up" data-aos-duration="3000" data-aos-easing="linear">
                                <Card className='testimonial-card'>
                                    <Card.Body>
                                        <div className="testimonial-img">
                                            <img src={Image} alt="" />
                                        </div>
                                        <div className="selling-rating">
                                            <IoStarSharp />
                                            <IoStarSharp />
                                            <IoStarSharp />
                                            <IoStarSharp />
                                            <IoStarSharp />
                                        </div>
                                        <p className='testimonial-card-text'>
                                            “Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown”
                                        </p>
                                        <div className="testimonial-name">
                                            <h5>KENT</h5>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='testimonial-card'>
                                    <Card.Body>
                                        <div className="testimonial-img">
                                            <img src={Image2} alt="" />
                                        </div>
                                        <div className="selling-rating">
                                            <IoStarSharp />
                                            <IoStarSharp />
                                            <IoStarSharp />
                                            <IoStarSharp />
                                            <IoStarSharp />
                                        </div>
                                        <p className='testimonial-card-text'>
                                            “Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown”
                                        </p>
                                        <div className="testimonial-name">
                                            <h5>KENT</h5>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col lg="3" className="testimonial-right">
                            <div className="testimonial-title">
                                <SectionName title="Testimonial" sub_title="what people think" sub_title2="" />
                            </div>
                            <Link to="#" className='view-all-btn'>
                                <img src={Image3} alt="" />
                                <span>View All</span>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Testimonial