import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';



function SectionName({ title, sub_title, sub_title2 }) {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear">
                <h4 className="company-right-about">{title}</h4>
                <h1 className="company-right-our">
                    {sub_title}<span className="company-right-company">{sub_title2}</span>
                </h1>
            </div>
        </>
    )
}

export default SectionName