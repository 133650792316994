import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image from '../images/our-company-img.png'
import Image1 from '../images/our-company-img1.png'
import { Link } from 'react-router-dom'
import SectionName from './SectionName'

const OurCompany = () => {
    return (
        <>
            <section className='company-bg'>
                <div className='company-main'>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <img className='company-img' src={Image} alt="About OUR COMPAnY" data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear"/>
                            </Col>
                            <Col lg="6">
                                <div className="company-right">
                                    <Row>
                                        <Col lg='10'>
                                            <div className="red-title">
                                                <SectionName title="Shop" sub_title="OUR" sub_title2="COMPANY" />
                                            </div>
                                        </Col>
                                        <Col lg='2'>
                                            <img src={Image1} alt="About OUR COMPAnY" />
                                        </Col>
                                    </Row>
                                    <p data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear">
                                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        <br />
                                        <br />
                                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <button className='custom_btn_card'><Link to="#">Read More</Link></button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default OurCompany