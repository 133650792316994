import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, Link } from 'react-router-dom';
import Logo from '../images/logo1.png';
import { CiSearch } from "react-icons/ci";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { LuUser2 } from "react-icons/lu";

const Header = () => {
  return (
    <>
      <div className='main_header'>
        <Navbar collapseOnSelect expand="lg" >
          <Container className='header-container'>
            <Navbar.Brand href="#home" className='navbar-logo'><img src={Logo} alt="" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className='main-nav'>
              <Nav className='main_navbar'>
                <NavLink to='#' className='nav_link'>Home</NavLink>
                <NavLink to='#' className='nav_link'>About Us</NavLink>
                <NavLink to='#' className='nav_link'>Football Helmets</NavLink>
                <NavLink to='#' className='nav_link'>Football Helmet Reconditioning</NavLink>
                <NavLink to='#' className='nav_link'>Football Shoulder Pads</NavLink>
                <NavLink to='#' className='nav_link'>Face Masks</NavLink>
                <NavLink to='#' className='nav_link'>Parts & Accessories</NavLink>
                <NavLink to='#' className='nav_link'>Custom Uniforms</NavLink>
              </Nav>
              <Nav className='main_navbar2'>
                <Link to='#' className='header-icon'><CiSearch /></Link>
                <Link to='#' className='header-icon'><HiOutlineShoppingBag /></Link>
                <Link to='#' className='header-icon'><LuUser2 /></Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  )
}

export default Header